.main-body {
  overflow: hidden;
  max-width: 767px;
  margin: 0 auto;
}

#highlightCompletedMatchBet_0 {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #d7d7d7;
}
.BLM-betSlipBox-close {
  background-color: #f5f7ff;
  border-left: 1px solid #d7d7d7;
  min-width: 30px;
  max-width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: red;
}
.BLM-betSlip-outcomeOdds {
  display: flex;
  justify-content: space-between;
  color: #164770;
}
.BLM-betSlip-outcome.truncate {
  font-size: 14px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}
.BLM-betSlip-market {
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  color: #f5174f;
  font-weight: 400;
  letter-spacing: 0.2px;
  line-height: 1.5;
}
.BLM-betSlip-fixture {
  color: #777;
  line-height: 16px;
  font-size: 11px;
  font-family: "Poppins", sans-serif;
}
.BLM-betSlip-fixture-league {
  font-weight: bold;
  color: #164770;
}
.BLM-betSlip-acceptOddsChange-container.p-2 {
  display: flex;
  justify-content: space-between;
  background-color: #f9f9f9;
  min-height: 60px;
  padding: 5px 12px;
  align-items: center;
}

.BLM-betSlip-clearAll {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #e03944;
  cursor: pointer;
  text-decoration: underline;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.2px;
  line-height: 1.5;
}
.BLM-checkboxLabel {
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  color: #f5174f;
  font-weight: 400;
  letter-spacing: 0.2px;
  line-height: 1.5;
}
.main-slip-st {
  background-color: white;
}
.BLM-addSelections-infoMsg {
  padding: 6px 12px;
  text-align: center;
  font-size: 11px;
  background-color: #ebefff;
  color: #e03944;
}
.BLM-stakeBox-container {
  display: flex;
  align-items: center;
  background-color: #d9e1ff;
  padding-left: 12px;
  justify-content: end;
}

.BLM-stakeInputBox.BLM-form-control {
  display: flex;
  justify-content: end;
  align-items: center;
}
.font-cls {
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  color: #222;
  font-weight: 400;
  letter-spacing: 0.2px;
  line-height: 1.5;
}

.BLM-accordion-header.BLM-BetslipArrowAfter {
  font-weight: 500;
  background-color: #d9e1ff;
  padding: 10px 12px;
  line-height: 20px;
  display: flex;
}
.BLM-totalStakeOddsWins-content {
  width: 95%;
}
.BLM-btnPrimary.active {
  background-color: #164770;
  color: #fff;
}
.bank-button.active {
  background-color: #ed1f51;
  color: #fff;
}

.BLM-accordion > .BLM-accordion-header.BLM-arrowAfter::after {
  display: flex;
  justify-content: center;
  align-items: center;
}

.BLM-betSlip-header {
  background-color: #164770;
  height: 50px;
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  z-index: 10;
}
#BLM-betSlip-closeButton {
  background-color: #164770;
  width: 50px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
}
.sb-icon_close {
  font-size: 16px;
  color: white;
}

.BLM-betSlip-txt {
  flex: 1 1 auto;
  font-size: 16px;
  font-weight: 500;
  padding-left: 10px;
  color: #fff;
}
.BLM-betSlip-header_right {
  padding-right: 10px;
  text-align: right;
}
.BLM-betSlip-content {
  height: 70vh;
}
.highlightRemoveClass {
  border-bottom: 2px solid #e03944 !important;
  border-top: 2px solid #e03944 !important;
  border-left: 2px solid #e03944 !important;
  border-right: 2px solid #e03944 !important;
  background-color: #fcf7f8;
}
.highlightRemoveClass .BLM-betSlipBox-close {
  border-left: 2px solid #e03944;
  background-color: #edd7dd;
}
